import React from 'react';
import './App.css';

import { NextUIProvider, Image} from "@nextui-org/react";
import Navigation from './components/Navigation'

import powerwasherman from './assets/images/powerwasherman.jpeg';

function App() {

  return (
    <NextUIProvider>
      <main className="dark text-foreground bg-background">
        {/* <Navigation /> */}
        <div className='md:container md:mx-auto'>
          <div className='hidden md:flex' style={{padding: '50px 10px'}}>
            <Image src={powerwasherman} width={500} />
            <h1 style={{ margin: 'auto', paddingLeft: '20px', fontSize: '3em', fontFamily: 'ComicBook' }} >one man.<br/>one powerwasher.<br/>one dream.</h1>
          </div>
          <div className='md:hidden' style={{padding: '50px 50px'}}>
            <Image src={powerwasherman} width={500} />
            <h1 style={{ margin: 'auto', padding: '50px 0', fontSize: '35px', fontFamily: 'ComicBook', textAlign: 'center'}} >one man.<br/>one powerwasher.<br/>one dream.</h1>
          </div>
        </div>
      </main>
    </NextUIProvider>
  );
}

export default App;
